<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2023-02-07 11:06:29
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-02-07 16:09:03
-->

<template>
  <el-card class="box">
    <el-button type="primary" size="small" @click="onEdit()" round icon="plus"
      >新增</el-button
    >
  </el-card>
  <el-card class="box data">
    <el-scrollbar>
      <div>
        <h3>收入项</h3>
        <div class="content_wrp">
          <div
            class="item"
            v-for="item in tableData.filter((x) => x.in_or_out == 0)"
            :key="item.id"
          >
            <span class="name">{{ item.name }}</span>
            <el-button link icon="edit" @click="onEdit(item)"></el-button>
            <el-popconfirm
              title="确定要删除？"
              @confirm="onDelete(item)"
              v-if="!item.is_system"
            >
              <template #reference>
                <el-button
                  type="danger"
                  link
                  size="small"
                  icon="delete"
                ></el-button>
              </template>
            </el-popconfirm>
          </div>
        </div>
      </div>
      <div>
        <h3>支出项</h3>
        <div class="content_wrp">
          <div
            class="item"
            v-for="item in tableData.filter((x) => x.in_or_out == 1)"
            :key="item.id"
          >
            <span class="name">{{ item.name }}</span>
            <el-button link icon="edit" @click="onEdit(item)"></el-button>
            <el-popconfirm
              title="确定要删除？"
              @confirm="onDelete(item)"
              v-if="!item.is_system"
            >
              <template #reference>
                <el-button
                  type="danger"
                  link
                  size="small"
                  icon="delete"
                ></el-button>
              </template>
            </el-popconfirm>
          </div>
        </div>
      </div>
    </el-scrollbar>
    <!-- <el-table v-loading="loading" :data="tableData" :border="true" :span-method="arraySpanMethod">
      <el-table-column label="类型" prop="in_or_out_text" width="150">
        <template #default="scope">
          {{ scope.row.in_or_out == 0 ? "收入项" : "支出项" }}
        </template>
      </el-table-column>
      <el-table-column label="名称" prop="name"></el-table-column>
      <el-table-column label="自定义" width="180">
        <template #default="scope">
          <div v-if="scope.row.is_system">--</div>
          <div v-else>自定义</div>
        </template>
      </el-table-column>
      <el-table-column label="停用" width="180">
        <template #default="scope">
          <el-tag type="danger" v-if="scope.row.is_stop">停用</el-tag>
          <el-tag type="success" v-else>正常</el-tag>
        </template>
      </el-table-column> 
      <el-table-column
        label="创建时间"
        prop="create_at"
        width="200"
      ></el-table-column>
      <el-table-column label="操作" width="140">
        <template #default="scope">
          <el-button
            type="primary"
            link
            size="small"
            @click="onEdit(scope.row)"
            v-if="!scope.row.is_system"
            >修改</el-button
          >
          <el-popconfirm
            title="去定要删除？"
            @confirm="onDelete(scope.row)"
            v-if="!scope.row.is_system"
          >
            <template #reference>
              <el-button type="danger" link size="small">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table> -->
  </el-card>
  <el-dialog
    :title="title"
    v-model="visibleDialog"
    width="600px"
    :close-on-click-modal="false"
    draggable
    :close-on-press-escape="false"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="类型">
        <el-radio-group v-model="form.in_or_out">
          <el-radio :label="0">收入项</el-radio>
          <el-radio :label="1">支出项</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="名称" prop="name">
        <el-col :span="16">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-col>
        <div class="help-block">使用后名称无法修改</div>
      </el-form-item>
      <el-form-item label="停用">
        <el-switch v-model="form.is_stop"></el-switch>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving"
        >确定</el-button
      >
    </template>
  </el-dialog>
</template>
  
  <script>
export default {
  data() {
    return {
      loading: false,
      saving: false,
      visibleDialog: false,
      title: "",
      tableData: [],
      form: {},
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    /**
     *
     */
    loadData() {
      this.loading = true;
      this.current = null;
      this.$http.get(`seller/v1/tallyBook/type`).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },

    /**
     *
     */
    onEdit(item) {
      this.title = item ? "修改" : "新增";
      this.form = {};
      this.form = Object.assign(
        {
          in_or_out: 0,
        },
        item
      );
      this.visibleDialog = true;
    },

    /**
     *
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("/seller/v1/tallyBook/type/edit", this.form)
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.$message.success(res.msg);
                this.loadData();
                this.visibleDialog = false;
              }
            })
            .cath(() => {
              this.saving = false;
            });
        }
      });
    },
    /**
     * 删除
     * @param {*} item
     */
    onDelete(item) {
      this.$http
        .get("/seller/v1/tallyBook/type/del?id=" + item.id)
        .then((res) => {
          if (res.code == 0) {
            this.$message({ type: "success", message: res.msg });
            this.loadData();
          }
        });
    },
  },
};
</script>
  
  <style scoped>
.content_wrp {
  display: flex;
  flex-wrap: wrap;
}
.content_wrp .item {
  margin-right: 20px;
  margin-bottom: 20px;
  border: 1px solid var(--el-border-color);
  padding: 15px 20px;
}
.content_wrp .item .name {
  margin-right: 20px;
}
</style>